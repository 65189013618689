/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import { SiteQueryQuery } from '../generated-types'

const LayoutMarkdown = ({ children, dark }) => {
  const data: SiteQueryQuery = useStaticQuery(graphql`
    query MdSiteQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            links {
              name
              link
            }
          }
        }
      }
    }
  `)

  return (
    <div className={dark ? 'dark' : ''}>
      <Header site={data.site} />
      <main className="markdown markdown-container">
        {children}
      </main>
      <Footer site={data.site}/>
    </div>
  )
}

LayoutMarkdown.propTypes = {
  children: PropTypes.node.isRequired
}

export default LayoutMarkdown
