import LayoutDarkMarkdown from "../../../../src/components/layout-dark-markdown";
import SEO from "../../../../src/components/seo";
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FaSolarPanel, FaHandHoldingUsd, FaAward } from 'react-icons/fa/index.esm.js';
import HomeSelector from "../../../../src/components/home-selector";
import { Feature, FeatureIcon, FeatureSummary } from "../../../../src/components/feature";
import React from 'react';
export default {
  LayoutDarkMarkdown,
  SEO,
  Link,
  graphql,
  Img,
  FaSolarPanel,
  FaHandHoldingUsd,
  FaAward,
  HomeSelector,
  Feature,
  FeatureIcon,
  FeatureSummary,
  React
};