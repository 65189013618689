import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import SEO from "../../../../src/components/seo";
import Hero from "../../../../src/components/hero";
import Tour from "../../../../src/components/tour";
import HomeSelector from "../../../../src/components/home-selector";
import React from 'react';
export default {
  Link,
  graphql,
  Img,
  BackgroundImage,
  SEO,
  Hero,
  Tour,
  HomeSelector,
  React
};