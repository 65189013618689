import React from 'react'

export const Feature = ({ children }) => (
  <div className="flex flex-row p-3 w-full items-center py-5">{children}</div>
)

export const FeatureIcon = ({ children }) => (
  <div className="w-2/12 self-stretch flex items-center justify-center">{children}</div>
)

export const FeatureSummary = ({ children }) => <div className="w-10/12 pl-10">{children}</div>
