import React from 'react'
import BackgroundImage from 'gatsby-background-image'


const Hero = ({ heroImage, children, styles }) => (
  <div className="escape-container flex flex-row flex-wrap">
    <div
      className="text-white bg-primary flex items-center justify-center w-full md:w-1/2 xl:w-4/12"
      style={{ minHeight: '20rem' }}
    >
      <div className="max-w-md mx-3">{children}</div>
    </div>
    <div className="w-full md:w-1/2 xl:w-8/12">
      <BackgroundImage
        className="w-full h-full"
        style={{ height: 500, ...styles }}
        Tag="section"
        fluid={heroImage.childImageSharp.fluid}
      >
        <div className="hero--overlay"></div>
      </BackgroundImage>
    </div>
  </div>
)

export default Hero
