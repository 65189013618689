import React from 'react'
import { SiteQueryQuery } from '../generated-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { groupBy } from 'lodash'

const Footer = (props: { site: SiteQueryQuery['site'] }) => {
  let { site } = props

  const data = useStaticQuery(graphql`
    query Footer {
      allModularHomesJson(
        filter: { floorplans: { elemMatch: { src: { id: { ne: null } } } }, hide: { eq: "" } }
      ) {
        nodes {
          id
          description
          name
          slug
        }
      }
    }
  `)
  let homesGroupedByName = groupBy(data.allModularHomesJson.nodes, h => h.name)

  return (
    <footer className="bg-gray-100 mt-auto border-t">
      <div className="container mx-auto p-5 flex flex-row flex-wrap justify-between leading-relaxed">
        <div className="all-products text-gray-800 flex-1">
          <h1 className="font-medium tracking-wide leading-normal">All Our Products</h1>
          <ul>
            {Object.entries(homesGroupedByName).map(([key, value], i) => (
              <li key={i}>
                <Link
                  className="text-gray-700"
                  key={value[0].id}
                  to={'/modular-homes/' + value[0].slug}
                >
                  {key}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h1 className="font-medium tracking-wide leading-normal">Pages</h1>
          {site.siteMetadata.menuLinks.map((link, i) => (
            <Link key={i} to={link.link} className="block text-gray-700">
              {link.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="container mx-auto p-5 text-gray-500 text-sm tracking-wide">
        Copyright © {new Date().getFullYear()} {site.siteMetadata.title} 
      </div>
    </footer>
  )
}

export default Footer
