import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { SiteQueryQuery } from '../generated-types'
import { IoIosMenu } from 'react-icons/io/index.esm.js'
import { FaCaretDown } from 'react-icons/fa/index.esm.js'
import LogoSVG from '../svgs/fluidsolar-teardrop.svg'
import { Manager, Reference, Popper } from 'react-popper'

const HeaderLink = props => {

  let timeoutId
  useEffect(() => {
    return () => clearTimeout(timeoutId)
  })

  let [isOpen, setopen] = useState(false)
  function close() {
    setopen(false)
    timeoutId = undefined
  }
  function closeSoon() {
    timeoutId = setTimeout(() => {
      close()
    }, 300)
  }
  function open() {
    clearTimeout(timeoutId)
    setopen(true)
  }

  return (
    <div onClick={close} onMouseLeave={closeSoon} onMouseEnter={open}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref}>
              <Link
                to={props.link.link}
                activeClassName="active"
                className="top-level block mt-0 mr-4 text-black py-2 border-b-2 border-transparent font-medium"
              >
                {props.link.links && <FaCaretDown className="inline"></FaCaretDown>}
                {props.link.name}
              </Link>
            </div>
          )}
        </Reference>
        {props.link.links && isOpen && (
          <Popper
            placement="bottom"
            modifiers={{ preventOverflow: { enabled: true, boundariesElement: 'window' } }}
            positionFixed={true}
          >
            {({ ref, style, placement }) => (
              <ul ref={ref} style={style} data-placement={placement} className="menu-dropdown rounded border">
                {props.link.links.map((link, i) => (
                  <Link
                    key={i}
                    to={link.link}
                    activeClassName="active"
                    className="block mt-0 mr-4 text-black py-2 border-b-2 border-transparent font-medium"
                  >
                    {link.name}
                  </Link>
                ))}
              </ul>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  )
}

const Header = (props: { site: SiteQueryQuery['site']; title?: string; homeURL?: string }) => {
  let { site, title, homeURL } = props
  let menuLinks = site.siteMetadata.menuLinks
  let commercialIndex = menuLinks.findIndex(l => l.link === '/commercial-inquiries')
  let firstLinks = menuLinks.slice(0, commercialIndex)
  let secondLinks = menuLinks.slice(commercialIndex)

  return (
    <header style={{ minHeight: '1em' }}>
      <nav className="container-lg mx-auto flex items-center justify-between flex-wrap px-2">
        <div className="logo flex items-center flex-shrink-0 mr-6 h-20">
          <Link className="flex items-center text-gray-800" to={homeURL || '/'}>
            <LogoSVG className="logo-svg" />

            <span className="logo-text block no-underline text-2xl font-bold pl-1">
              {title || site.siteMetadata.title}
            </span>
          </Link>
        </div>
        <ul
          className={
            'menu-links flex items-center w-auto flex-wrap'
          }
        >
          {firstLinks.map((link, i) => (
            <HeaderLink key={i} link={link} />
          ))}
          <div className="divider mr-4 h-8 border-l border-gray-300"></div>
          {secondLinks.map((link, i) => (
            <HeaderLink key={i} link={link} />
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header
