module.exports = [{
      plugin: require('/home/hayden/dev/fluidsolarthermal.com/web/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png"},
    },{
      plugin: require('/home/hayden/dev/fluidsolarthermal.com/web/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":2000}}],"remarkPlugins":[null]},
    },{
      plugin: require('/home/hayden/dev/fluidsolarthermal.com/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-157541970-2"},
    },{
      plugin: require('/home/hayden/dev/fluidsolarthermal.com/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
