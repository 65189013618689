import React, { useState, useEffect } from 'react'
import HomeCard from './home-card'
import { OurHomesQuery } from '../generated-types'
import { useStaticQuery, graphql } from 'gatsby'
import { groupBy } from 'lodash-es'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

type LevelOption = { value: number; name: string }
let levelOptions: LevelOption[] = [
  {
    value: 1,
    name: 'Single storey'
  },
  {
    value: 2,
    name: 'Two storey'
  }
]
type BedroomOption = { value: number; name: string }
let bedroomOptions: BedroomOption[] = [1, 2, 3, 4].map(value => ({
  value,
  name: value > 1 ? `${value} bedrooms` : `${value} bedroom`
}))

type HomeScores = { [id: string]: number }

function HomeCardWrapper({
  homes,
  scores
}: {
  homes: OurHomesQuery['allModularHomesJson']['nodes']
  scores: HomeScores
}) {
  let firstHome = homes[0]
  return (
    <div className="w-full lg:w-1/2 p-1">
      <HomeCard homes={homes} />
      {process.env.NODE_ENV === 'development' ? scores[firstHome.id] : ''}
    </div>
  )
}

type Props = {
  category: 'categoryHome' | 'categoryAddition' | 'categoryRefuge'
}

let HomeSelector = (props: Props) => {
  const CATEGORY_TEXT =
    {
      categoryHome: 'Your Home',
      categoryAddition: 'Your Addition',
      categoryRefuge: 'Your Emergency Solution'
    }[props.category] || 'Your Home'

  const data: OurHomesQuery = useStaticQuery(graphql`
    query OurHomes {
      allModularHomesJson(
        filter: { floorplans: { elemMatch: { src: { id: { ne: null } } } }, hide: { eq: "" } }
      ) {
        nodes {
          ...HomeFields
          id
          bedrooms
          bathroom
          description
          name
          twoLevels
          categoryHome
          categoryRefuge
          categoryAddition
          exteriors {
            side
            src {
              id
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  let [storeyState, setStoreyState] = useState()
  let [bedroomsState, setBedroomsState] = useState()

  const scores: HomeScores = {}

  const filteredData = data.allModularHomesJson.nodes
    .map(home => {
      let score = 0

      if (bedroomsState) {
        // treat study like a bedroom for sizing purposes
        const bedroomsAndStudys = Number(home.bedrooms) + Number(home.study != '')
        let bedroomErr = Math.abs(bedroomsAndStudys - bedroomsState.value)
        const maxBedroomErr = 5 - 1
        score += 1 - bedroomErr / maxBedroomErr
      }

      if (storeyState) {
        const selectedIsTwoLevels = storeyState.value === 2
        if (Boolean(Number(home.twoLevels)) === selectedIsTwoLevels) {
          score += 1
        }
      }

      if (props.category) {
        if (home[props.category] === 'TRUE') {
          score += 1
        }
      }

      scores[home.id] = score
      return home
    })
    .filter(home => scores[home.id] > 1)
    .sort((a, b) => scores[b.id] - scores[a.id])

  const topScore = Math.max(...filteredData.map(h => scores[h.id]))
  let homesGroupedByName = groupBy(filteredData, h => h.name)
  let topHomeGroups = Object.values(homesGroupedByName).filter(
    homes => scores[homes[0].id] === topScore
  )
  let otherHomeGroups = Object.values(homesGroupedByName).filter(
    homes => scores[homes[0].id] < topScore
  )

  function updateParams(field: 'storey' | 'bedrooms', value: string) {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(field, value)
    window.history.replaceState({}, '', `${location.pathname}?${searchParams}`)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has('bedrooms')) {
      setBedroomsState(bedroomOptions.find(b => b.value === Number(searchParams.get('bedrooms'))))
    } else {
      setBedroomsState(bedroomOptions[0])
    }

    if (searchParams.get('storey')) {
      setStoreyState(levelOptions.find(l => l.value === Number(searchParams.get('storey'))))
    } else {
      setStoreyState(levelOptions[0])
    }
  }, [])

  function onClickStorey(s: LevelOption) {
    setStoreyState(s)
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Home selector storey',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: s.name
    })
    updateParams('storey', String(s.value))
  }

  function onClickBedrooms(s: BedroomOption) {
    setBedroomsState(s)
    trackCustomEvent({
      category: 'Home selector bedrooms',
      action: 'Click',
      label: s.name
    })
    updateParams('bedrooms', String(s.value))
  }

  return (
    <>
      <div className="container mx-auto px-2">
        <h1 className="text-2xl text-black inline">Find {CATEGORY_TEXT}. </h1>
        <span className="text-xl text-gray-800">I'm looking for&hellip;</span>

        <div className="py-2">
          <div className="inline-flex btn-group">
            {levelOptions.map(s => (
              <button
                className={'outline-button ' + (s === storeyState ? 'selected' : '')}
                key={s.value}
                onClick={() => onClickStorey(s)}
              >
              {s.name}
              </button>
            ))}
          </div>
        </div>

        <div className="py-2">
          <div className="inline-flex flex-wrap btn-group">
            {bedroomOptions.map(s => (
              <button
                className={s === bedroomsState ? 'selected outline-button' : 'outline-button'}
                key={s.value}
                onClick={() => onClickBedrooms(s)}
              >
                {s.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="container mx-auto px-2 pb-5 flex flex-wrap">
        {topHomeGroups.map((homes, i) => (
          <HomeCardWrapper key={i} homes={homes} scores={scores}></HomeCardWrapper>
        ))}
      </div>
      {otherHomeGroups.length > 0 && (
        <div className="py-3 mt-10">
          <div className="container mx-auto px-2 py-5">
            <details open>
              <summary>
                <h2 className="mt-0 text-2xl font-bold inline">All Other Homes</h2>
              </summary>
              <div className="flex flex-wrap">
                {otherHomeGroups.map((homes, i) => (
                  <HomeCardWrapper key={i} homes={homes} scores={scores}></HomeCardWrapper>
                ))}
              </div>
            </details>
          </div>
        </div>
      )}
    </>
  )
}

export default HomeSelector
