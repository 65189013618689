import React from 'react'
import { FaBed, FaBath, FaDollarSign } from 'react-icons/fa/index.esm.js'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { OurHomesQuery } from '../generated-types'
import { calculateCost } from '../calculations'

function getFirstExterior(home) {
  return home.exteriors && home.exteriors[0]
}

function HomeCard({ homes }: { homes: OurHomesQuery['allModularHomesJson']['nodes'] }) {
  // sort() to make sure we feature a home that actually has an exterior image
  let home = homes.sort(
    (a, b) => Number(Boolean(getFirstExterior(b))) - Number(Boolean(getFirstExterior(a)))
  )[0]
  let { baseCost } = calculateCost(home)
  let firstExterior = getFirstExterior(home)
  return (
    <Link
      className="bg-white h-full"
      to={'/modular-homes/' + home.slug}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div className="overflow-hidden shadow flex flex-row h-full">
        {firstExterior && (
          <div className="w-1/2">
            {/* h-full here could cause problems, messes with aspect ratio. watch out */}
            <Img className="h-full" fluid={firstExterior.src.childImageSharp.fluid} />
          </div>
        )}
        {/* width for truncate */}
        <div className="w-1/2 flex flex-col bg-white">
          <div className="px-6 py-4">
            <div className="font-bold text-sm text-gray-500 mb-1">{home.name}</div>
            <div className="text-base font-semibold">
              <span className="mr-1">From</span>${baseCost}
            </div>
            <div className="text-gray-700 text-base mb-0 truncate">
              {home.description}
              {/* TODO: nested link errors in console */}
              {/* {homes.length > 1 &&
                  homes
                    .filter(h => h.id !== home.id)
                    .map(h => (
                      <Link key={h.id} className="pl-2" to={'/modular-homes/' + h.slug}>
                        {h.description}
                      </Link>
                    ))} */}
            </div>
            {homes.length > 1 && (
              <div className="text-gray-700 text-sm truncate">
                <em>
                  {homes.length - 1} other variation{homes.length - 1 > 1 ? 's' : ''}
                </em>
              </div>
            )}
          </div>
          <div className="flex px-6 py-4 items-center mt-auto">
            <div className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
              <div className="flex items-center">
                <FaBed className="mr-1" /> {home.bedrooms}
              </div>
            </div>
            <div className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
              <div className="flex items-center">
                <FaBath className="mr-1" /> {home.bathroom}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default HomeCard
