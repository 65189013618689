import React, { useState, useEffect } from 'react'
import { HomeByIdQuery } from '../generated-types'
import Img from 'gatsby-image'
import { IoMdVideocam } from 'react-icons/io/index.esm.js'
import {
  FaChevronCircleRight,
  FaChevronCircleLeft,
  FaTimesCircle
} from 'react-icons/fa/index.esm.js'
import EmblaCarouselReact from 'embla-carousel-react'
import EmblaCarousel from 'embla-carousel'

type Props = {
  modularHomesJson: HomeByIdQuery['modularHomesJson']
  floorplan: HomeByIdQuery['modularHomesJson']['floorplans'][0]
  internal: boolean
  external: boolean
}

type Interior = HomeByIdQuery['modularHomesJson']['floorplans'][0]['internals'][0]
type Exterior = HomeByIdQuery['modularHomesJson']['exteriors'][0]
type InteriorOrExteriorRenderType = Exterior | Interior

// Clockwise on floor plan, 0 starting at top
// 3d images "towards right"
let sides = [
  {
    top: 10,
    left: 50
  },
  {
    top: 10,
    left: 90
  },
  {
    top: 50,
    left: 90
  },
  {
    top: 90,
    left: 90
  },
  {
    top: 90,
    left: 50
  },
  {
    top: 90,
    left: 10
  },
  {
    top: 50,
    left: 10
  },
  {
    top: 10,
    left: 10
  }
]

function Camera({
  floorplan,
  preview,
  setSelectedPreview,
  selectedPreview,
  mode
}: {
  floorplan
  preview: InteriorOrExteriorRenderType
  setSelectedPreview
  selectedPreview
  mode: Mode
}) {
  let width = floorplan.src.childImageSharp.original.width
  let height = floorplan.src.childImageSharp.original.height
  let midX = width / 2
  let midY = height / 2

  let cameraX: number
  let cameraY: number

  let angleRadians: number
  let left: string
  let top: string
  let title: string

  if ('rotate' in preview) {
    // interior
    cameraX = preview.x
    cameraY = preview.y
    angleRadians = Math.atan2(midY - cameraY, midX - cameraX)
    left = (cameraX / width) * 100 + '%'
    top = (cameraY / height) * 100 + '%'
  }

  if ('side' in preview) {
    // exterior
    let side = sides[preview.side]
    cameraX = side.left
    cameraY = side.top
    angleRadians = Math.atan2(50 - cameraY, 50 - cameraX)
    left = side.left + '%'
    top = side.top + '%'
    title = String(preview.side)
  }

  // Calculate rotation to angle arrow towards centre
  let angleDeg = (angleRadians * 180) / Math.PI
  // Round to nearest 45
  // TODO: customise angle
  let angleDegRounded = Math.round(angleDeg / 45) * 45

  if ('rotate' in preview) {
    // Override rotation
    if (preview.rotate) {
      angleDegRounded = preview.rotate
    }
  }

  let classNames = ['border-none', 'bg-transparent', 'padding-3']

  if (selectedPreview === preview) {
    classNames.push('text-primary-light')
  } else if (mode === Mode.GALLERY) {
    classNames.push('invisible')
  }

  return (
    <button
      key={preview.src.id}
      className={classNames.join(' ')}
      style={{
        userSelect: 'none',
        position: 'absolute',
        left: left,
        top: top,
        transform: `translateX(-50%) translateY(-50%) rotate(${angleDegRounded}deg)`
      }}
      title={title}
      onClick={e => {
        e.stopPropagation()
        setSelectedPreview(preview)
      }}
    >
      <IoMdVideocam size="2em"></IoMdVideocam>
    </button>
  )
}

enum Mode {
  GALLERY = 1,
  FLOORPLAN = 2
}

function Tour(props: Props) {
  let { modularHomesJson, floorplan, internal, external } = props

  if (floorplan == null) {
    // default to first floorplan
    floorplan = modularHomesJson.floorplans[0]
  }
  let exteriors = modularHomesJson.exteriors.sort((a, b) => a.side - b.side)
  let allPreviews: InteriorOrExteriorRenderType[] = []
    .concat(internal ? floorplan.internals : [])
    .concat(external ? exteriors : [])

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const [selectedIndex, setSelectedIndex] = useState(0)
  const selectedIndexPreview = allPreviews[selectedIndex]

  const [mode, setMode] = useState(Mode.GALLERY)

  function setSelectedPreview(preview) {
    let index = allPreviews.indexOf(preview)
    if (mode === Mode.GALLERY) {
      embla1.scrollTo(allPreviews.indexOf(preview)) // implicitly calls setSelectedIndex()

      let scrollToSnap2 = embla2.scrollSnapList().findIndex(s => s.slideIndexes.includes(index))
      embla2.scrollTo(scrollToSnap2)
    } else if (mode === Mode.FLOORPLAN) {
      setSelectedIndex(index)
    }
  }

  const [embla1, setEmbla1] = useState<EmblaCarousel>(null)
  const [embla2, setEmbla2] = useState<EmblaCarousel>(null)

  useEffect(() => {
    const onSelect = () => {
      let index = embla1.selectedScrollSnap()
      setSelectedIndex(index)

      setPrevBtnEnabled(embla1.canScrollPrev())
      setNextBtnEnabled(embla1.canScrollNext())

      let scrollToSnap2 = embla2.scrollSnapList().findIndex(s => s.slideIndexes.includes(index))
      embla2.scrollTo(scrollToSnap2)
    }

    if (embla1) {
      embla1.on('select', onSelect)
      onSelect()
    }
    return () => embla1 && embla1.destroy()
  }, [embla1])

  function onClickThumbnail(preview) {
    // clickAllowed info: https://github.com/davidcetinkaya/embla-carousel/releases/tag/v2.6.0
    if (embla2.clickAllowed()) {
      setSelectedPreview(preview)
    }
  }

  return (
    <div className="tour">
      {mode === Mode.GALLERY && (
        <div className="relative">
          <EmblaCarouselReact
            className="print:avoid-page-break"
            emblaRef={setEmbla1}
            options={{ loop: false }}
          >
            <div className="embla__container">
              {allPreviews.map((preview, index) => {
                return (
                  <div className="embla__slide" key={index}>
                    <Img
                      // eager load adjacent images
                      loading={Math.abs(selectedIndex - index) < 2 ? 'eager' : 'lazy'}
                      key={preview.src.id}
                      fluid={preview.src.childImageSharp.fluid}
                    />
                  </div>
                )
              })}
            </div>
          </EmblaCarouselReact>

          <button
            disabled={!prevBtnEnabled}
            className="embla__button embla__button--prev print:hidden"
            onClick={() => embla1.scrollPrev()}
          >
            <FaChevronCircleLeft
              style={{
                width: 45,
                height: 45
              }}
            />
          </button>
          <button
            disabled={!nextBtnEnabled}
            className="embla__button embla__button--next print:hidden"
            onClick={() => embla1.scrollNext()}
          >
            <FaChevronCircleRight
              style={{
                width: 45,
                height: 45
              }}
            />
          </button>

          <div
            className="absolute inset-0 h-full w-full opacity-50 hover:opacity-100 pr-2 pt-2"
            style={{
              transform: 'scale(0.4)',
              transformOrigin: '100% 0%'
            }}
          >
            <Floorplan
              external={external}
              internal={internal}
              floorplan={floorplan}
              setSelectedPreview={setSelectedPreview}
              setSelectedIndex={setSelectedIndex}
              selectedIndexPreview={selectedIndexPreview}
              exteriors={exteriors}
              mode={mode}
              setMode={setMode}
            ></Floorplan>
          </div>
        </div>
      )}
      {/* end Mode.GALLERY */}

      {mode === Mode.FLOORPLAN && (
        <div className="relative">
          <Floorplan
            external={external}
            internal={internal}
            floorplan={floorplan}
            setSelectedIndex={setSelectedIndex}
            setSelectedPreview={setSelectedPreview}
            selectedIndexPreview={selectedIndexPreview}
            exteriors={exteriors}
            mode={mode}
            setMode={setMode}
          ></Floorplan>
          <div
            className={`absolute inset-0 bg-white cursor-pointer ${
              selectedIndexPreview != null ? 'block' : 'hidden'
            }`}
            onClick={() => setSelectedIndex(null)}
          >
            {selectedIndexPreview && (
              <Img className="max-h-full" fluid={selectedIndexPreview.src.childImageSharp.fluid} />
            )}
          </div>
          {selectedIndexPreview && (
            <button
              onClick={() => setSelectedIndex(null)}
              className="absolute right-0 top-0 text-gray-900 hover:text-black"
            >
              <FaTimesCircle className="p-5 w-20 h-20" />
            </button>
          )}
        </div>
      )}
      {/* end Mode.FLOORPLAN */}

      {/* Thumbnails */}
      {mode === Mode.GALLERY && (
        <EmblaCarouselReact
          emblaRef={setEmbla2}
          options={{ loop: false, containScroll: true, draggable: true, dragFree: false }}
        >
          <div className="embla__container">
            {allPreviews.map((preview, index) => {
              let thumbnailFluid = {
                ...preview.src.childImageSharpThumbnail.fluid,
                sizes: `15vw` // roughly ~20% (container size changes)
              }
              return (
                <div
                  className={`embla__slide`}
                  style={{ width: '20%', paddingLeft: 0 }}
                  key={index}
                >
                  <button className="w-full" onClick={() => onClickThumbnail(preview)}>
                    <Img
                      key={preview.src.id}
                      fluid={thumbnailFluid}
                      className={`border-4 ${
                        preview === selectedIndexPreview
                          ? 'border-primary-light'
                          : 'border-transparent'
                      }`}
                    />
                  </button>
                </div>
              )
            })}
          </div>
        </EmblaCarouselReact>
      )}
    </div>
  )
}

function Floorplan(props: {
  external
  internal
  floorplan
  setSelectedIndex
  setSelectedPreview
  selectedIndexPreview
  exteriors
  mode: Mode
  setMode
}) {
  let {
    external,
    internal,
    floorplan,
    setSelectedIndex,
    setSelectedPreview,
    selectedIndexPreview,
    exteriors,
    mode,
    setMode
  } = props

  function toggleMode() {
    if (mode === Mode.GALLERY) {
      setSelectedIndex(null)
      setMode(Mode.FLOORPLAN)
    } else {
      setMode(Mode.GALLERY)
    }
  }

  return (
    <div
      className={`relative mx-auto w-full ${mode === Mode.GALLERY ? '' : ''}`}
      style={{
        padding: external ? 50 : 0,
        backgroundColor: 'white',
        cursor: mode === Mode.GALLERY ? 'zoom-in' : 'zoom-out'
      }}
      onClick={toggleMode}
    >
      <div className="relative">
        <Img className="print:avoid-page-break" fluid={floorplan.src.childImageSharp.fluid} />
        {internal &&
          (floorplan.internals || []).map(internal => {
            return (
              <Camera
                key={internal.src.id}
                floorplan={floorplan}
                preview={internal}
                setSelectedPreview={setSelectedPreview}
                selectedPreview={selectedIndexPreview}
                mode={mode}
              ></Camera>
            )
          })}
      </div>

      {external &&
        (exteriors || []).map(exterior => {
          return (
            <Camera
              key={exterior.src.id}
              floorplan={floorplan}
              preview={exterior}
              setSelectedPreview={setSelectedPreview}
              selectedPreview={selectedIndexPreview}
              mode={mode}
            ></Camera>
          )
        })}
    </div>
  )
}

export default Tour
