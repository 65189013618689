import SEO from "../../../../src/components/seo";
import HomeSelector from "../../../../src/components/home-selector";
import Hero from "../../../../src/components/hero";
import { graphql } from 'gatsby';
import React from 'react';
export default {
  SEO,
  HomeSelector,
  Hero,
  graphql,
  React
};