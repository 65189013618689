import variables from './variables.json'
import { HomeByIdQuery } from './generated-types'

export type Variable = {
  name: string
  baseUnitCost: number
  segmentCost: number
  areaCost: number
  siteCost: number
  luxuryCost: number
}

type OptionMeta = { [k in keyof typeof variables]?: {
  type?: string,
  order?: number,
  group?: string,
  default?: boolean
} }

export let optionMeta: OptionMeta = {
  extraDeck: {
    type: 'checkbox',
    order: 2
  },
  extraBalcony: {
    type: 'checkbox',
    order: 2
  },
  offGridPvPackage: {
    type: 'checkbox',
    order: 0
  },
  solarHeating: {
    type: 'checkbox',
    order: 0
  },
  solarCooling: {
    type: 'checkbox',
    order: 0
  },
  elevated: {
    type: 'checkbox',
    order: 2
  },
  courtyard: {
    type: 'checkbox',
    order: 2
  },
  study: {
    type: 'checkbox',
    order: 2
  },
  extraLiving: {
    type: 'checkbox',
    order: 2
  },
  modern: {
    group: 'style',
    type: 'checkbox',
    default: true,
    order: 1
  },
  funky: {
    group: 'style',
    type: 'checkbox',
    order: 1
  },
  parapet: {
    group: 'style',
    type: 'checkbox',
    order: 1
  },
  metro: {
    group: 'style',
    type: 'checkbox',
    order: 1
  },
  freeStandingGarage: {
    type: 'checkbox',
    order: 2
  },
  freeStandingCarport: {
    type: 'checkbox',
    order: 2
  },
  luxuryPackage: {
    type: 'checkbox',
    order: 4
  },

  // Not user fillable, just here so base cost calculation skips it
  solarHvacPackageDiscount: {
    order: 0
  },
  offGridPackageDiscount: {
    order: 0
  },
  fireResistantPackage: {}
}


export function calculateCost(home: HomeByIdQuery['modularHomesJson'], luxurySelected = false) {
  let baseCost = 0
  let siteCost = 0

  for (let key of Object.keys(variables)) {
    if (key in optionMeta) {
      continue
    }
    let value = Number(home[key])
    const v: Variable = variables[key]
    let oneOrZero = value > 0 ? 1 : 0

    baseCost +=
      value * v.baseUnitCost +
      oneOrZero * v.segmentCost * Number(home.totalSegments) +
      oneOrZero * v.areaCost * Number(home.totalArea)
    if (luxurySelected) {
      baseCost += oneOrZero * v.luxuryCost
    }

    siteCost += oneOrZero * v.siteCost
  }
  return {
    baseCost,
    siteCost
  }
}


export function calculateOptionCost(
  value: number,
  v: Variable,
  home: HomeByIdQuery['modularHomesJson'],
  luxury = false
) {
  let cost =
    value * v.baseUnitCost +
    value * v.segmentCost * Number(home.totalSegments) +
    value * v.areaCost * Number(home.totalArea) +
    value * v.siteCost
  if (luxury) {
    cost += value * v.luxuryCost
  }
  return cost
}
