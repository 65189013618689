import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from "../../../../src/components/seo";
import Tour from "../../../../src/components/tour";
import React from 'react';
export default {
  Link,
  graphql,
  Img,
  SEO,
  Tour,
  React
};